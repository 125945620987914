import { useState, useEffect } from "react";
import inspectionService from "../services/inspectionService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetAllOpenInspectionsByUser = (kt) => {
  const [inspections, setInspections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }

  useEffect(() => {
    !isTokenExpired &&
    inspectionService
      .getAllOpenInspectionsByUser(kt, user.accessToken.rawData)
      .then((inspections) => {
        inspections.forEach(inspection => {
          inspection.companyName = inspection.establishmentName
        });
        setInspections(inspections);
        console.log(inspections);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [kt, user.accessToken.rawData, isTokenExpired]);
  return { inspections, isLoading, error };
};
export default useGetAllOpenInspectionsByUser;