import { Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import CancelButton from "../CancelButton/CancelButton";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import useGetAllInspectionItems from "../../../hooks/getAllInspectionItems";
import SearchBar from "../../SearchBar/SearchBar";
import useSearchBar from "../../../hooks/useSearchBar";
import './InspectionFollowUpAddDeviationItemModal.css';


const InspectionFollowUpAddDeviationItemModal = ({ open, handleClose, inspectionTypeId, handleAddItemsToFollowUp, handleCloseAddInspectionTypeModal, followUpChecksToShow }) => {
  const { inspectionItemsList } = useGetAllInspectionItems(inspectionTypeId.toString());
  const [itemsToAdd, setItemsToAdd] = useState([]);
  const { searchResult, handleSearchBarChange, searchTerm} = useSearchBar(inspectionItemsList, "isName");
  const itemsToShow = searchResult.slice(0,100)
  const handleSubmit = () => {
    handleAddItemsToFollowUp(itemsToAdd)
    handleClose();
    handleCloseAddInspectionTypeModal();
  }
  const handleItemClick = (item) => {
    if(!followUpChecksToShow.some(f => f.itemId === item.id)) {
      const itemIndex = itemsToAdd.find(i => i.itemId === item.id);
      if(itemIndex) {
        item.isInList = false;
        setItemsToAdd(itemsToAdd.filter((i) => i.itemId !== item.id));
      }else {
        item.isInList = true;
        const itemToAdd = {
          deadlineToResolve: null,
          comment: "",
          inspectionDeviationImages: [],
          inspectionTypeId: item.inspectionTypeId,
          inspectionTypeIsName: item.inspectionTypeIsName,
          itemId: item.id,
          itemName: item.isName,
          judgementId: "",
          judgementIsName: ""
        }
        setItemsToAdd([...itemsToAdd, itemToAdd])
      }
    }
  }
  return (
    <Modal
      className="inspection-followup-add-deviation-item-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="inspection-followup-add-deviation-item-modal-paper">
        <div><b>Veljið skoðunaratriði</b></div>
        <SearchBar 
          handleSearchBarChange={handleSearchBarChange} 
          searchTerm={searchTerm}
          placeHolder="Leita að skoðunaratriði"
        />
        <Table className="" size="small">
          <TableHead>
            <TableRow>
              <TableCell><b>Skoðunaratriði</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              itemsToShow.map(item => 
                <TableRow className='inspection-followup-add-item-list-row'>
                  <TableCell key={item.id} style={{backgroundColor: item.isInList ? "#95FF8C" : "white"}} onClick={() => handleItemClick(item)}>{item.isName}</TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
        <div className="inspection-followup-add-deviation-item-modal-button-area">
          <CancelButton
            handleCancel={handleClose} 
            title="Hætta við" />
          <ConfirmationButton 
            handleSubmit={ handleSubmit } 
            title="Bæta við" />
        </div>
      </Paper>
    </Modal>
  );
};
export default InspectionFollowUpAddDeviationItemModal;