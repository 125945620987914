import { TextField, TextareaAutosize } from "@material-ui/core";
import CalendarSelection from "../CalendarSelection/CalendarSelection";
import "./InspectionAdditionalInfoInput.css";
import InspectionNumberInput from "../InspectionNumberInput/InspectionNumberInput";

const InspectionAdditionalInfoInput = ( {inspectionValues, handleChange, errors} ) => {
  const helperTextColor = inspectionValues.comment.length < 300 ? "green" : "red";

  return (
    <>
      <div className="inspection-additional-info-view-upper">
        <div className="inspection-additiona-info-calendar">
          <CalendarSelection 
            values={inspectionValues} 
            valueName="inspectionPerformed" 
            helperText="Eftirlit framkvæmt" 
            label="Dagsetning"
            errors={errors}
          />
        </div>
        <div>
          <TextField 
            label={errors.presentName ? errors.presentName : "Viðstaddir" }  
            variant="standard"
            onChange={handleChange}
            value={inspectionValues.presentName}
            error={errors.presentName}
            name="presentName"
          />
        </div>
      </div>
      <div className="inspection-additional-info-view-hours">
        <div className="inspection-additional-info-view-hours-divs">
          <InspectionNumberInput 
            handleChange={handleChange}
            errors={errors}
            inspectionValues={inspectionValues}
            name="billingHourPreperation"
            helperText="Klukkustundir"
            label="Undirbúningur"
            />
          <InspectionNumberInput 
            handleChange={handleChange}
            inspectionValues={inspectionValues}
            errors={errors}
            name="billingHour"
            helperText="Klukkustundir"
            label="Framkvæmd"
          />
        </div>
        <div className="inspection-additional-info-view-hours-divs">
          <InspectionNumberInput 
            handleChange={handleChange}
            inspectionValues={inspectionValues}
            errors={errors}
            name="billingHourTransport"
            helperText="Klukkustundir"
            label="Akstur"
          />
          <InspectionNumberInput 
            handleChange={handleChange}
            inspectionValues={inspectionValues}
            errors={errors}
            name="billingHourExtra"
            helperText="Klukkustundir"
            label="Frágangur"
          />
        </div>
      </div>
      <div className="inspection-additional-info-comment">
        <div>Skýring</div>
        <TextareaAutosize
          aria-label="empty textarea"
          value={inspectionValues.comment}
          onChange={handleChange}
          name="comment"
          placeholder="Skrá skýringu hér ..."
          maxLength={550}
          style={{
            minWidth: 350,
            minHeight: 100,
            maxWidth: 1200,
            maxHeight: 300,
          }}
        />
        <div style={{color: helperTextColor}}>{inspectionValues.comment.length} / 550</div>
      </div>
    </>
  );
}

export default InspectionAdditionalInfoInput;