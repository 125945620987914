import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import { getMonths } from "../../helpers/months";
import "./InspectionItemsListItem.css";

const InspectionItemsListItem = ({ item, handleOpenInspectionItemModal, secondaryValue, values, previousDeviations }) => {
  const itemIsSelected = values.inspectionDeviations.find(i => i.itemId === item.id);
  const previousDeviation = previousDeviations.find(pd => pd.itemId === item.id);
  const judgmentName = itemIsSelected && itemIsSelected.judgementId === 2 ? "Frávik" : "Alvarlegt frávik";
  const itemDate = itemIsSelected && itemIsSelected.deadlineToResolve;
  console.log(item);
  let dateToShow;
  if(itemDate) {
    const months = getMonths;
    const dateArray = itemDate.split("-");
    dateToShow = dateArray[0] + "." + months[dateArray[1] - 1].isName + " " + dateArray[2];
  }else {
    dateToShow = "Næsta skoðun"
  }
  return (
    <TableRow className="inspection-items-list-item" style={itemIsSelected && {backgroundColor: 'rgb(255, 217, 217)'}} onClick={() => handleOpenInspectionItemModal(item)}>
        <TableCell>{item.isName}{previousDeviation && <FlagIcon style={{marginLeft: "5px", marginTop: "-5px", color: "orange"}}/>}</TableCell>
        <TableCell align="right">{itemIsSelected ? <div>{judgmentName} - {dateToShow}</div> : item[secondaryValue]}</TableCell>
    </TableRow>
  );
};

export default InspectionItemsListItem;
