import CancelButton from "../CancelButton/CancelButton";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import "./InspectionAdditionalInfoView.css";
import SaveButton from "../SaveButton/SaveButton";
import InspectionAdditionalInfoInput from "../InspectionAdditionalInfoInput/InspectionAdditionalInfoInput";

const InspectionAdditionalInfoView = ( {inspectionValues, handleChange, errors, handleSave, handleFinish, handleCancelInspection} ) => {
  console.log(inspectionValues)
  return (
    <div className="inspection-additional-info-view">
      <InspectionAdditionalInfoInput inspectionValues={inspectionValues} handleChange={handleChange} errors={errors}/>
      <div className="inspection-additional-info-buttons">
        <div className="inspection-additional-info-buttons-upper">
          <CancelButton title="Hætta skráningu" handleCancel={handleCancelInspection}/>
          <ConfirmationButton
            title="Klára skráningu"
            handleSubmit={handleFinish}
          />
        </div>
        <div className="inspection-additional-info-buttons-lower">
          <SaveButton 
            title="Vista skráningu"
            handleSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
}

export default InspectionAdditionalInfoView;