import { useState, useEffect } from "react";
import inspectionScheduleService from "../services/inspectionScheduleService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetAllInspectionSchedule = () => {
  const [inspectionSchedule, setInspectionSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }
  useEffect(() => {
    !isTokenExpired &&
    inspectionScheduleService
      .getAllInspectionSchedule(user.accessToken.rawData)
      .then((inspectionSchedule) => {
        inspectionSchedule.forEach(item => {
          item.searchKey = item.name + " " + item.identity + " " + item.companyName + " " + item.reasonName + " " + item.id + " " +  item.zipCode;
          if(!item.zipCode) {
            item.zipCode = "";
          }
        });
        setInspectionSchedule(inspectionSchedule);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [user.accessToken.rawData, isTokenExpired]);
  return { inspectionSchedule, isLoading, error };
};
export default useGetAllInspectionSchedule;