import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useGetFollowUpChecksById from '../../../hooks/useGetFollowUpChecksById';
import { Box, Step, StepButton, Stepper } from '@mui/material';
import useWindowSize from '../../../hooks/useWindowSize';
import "./InspectionFollowUpContainer.css"
import InspectionFollowUpView from '../InspectionFollowUpView/InspectionFollowUpView';
import useForm from '../../../hooks/useForm';
import inspectionValidation from '../../../validations/inspectionValidation';
import InspectionFollowUpAdditionalInfo from '../InspectionFollowUpAdditionInfo/InspectionFollowUpAdditionalInfo';
import useCreateFollowUpInspection from '../../../hooks/useCreateFollowUpInspection';
import { useSelector } from 'react-redux';
import InspectionConfirmationModal from '../InspectionConfirmationModal/InspectionConfirmationModal';
import useCloseInspection from '../../../hooks/useCloseInpsection';
import useUpdateFollowUpInspection from '../../../hooks/useUpdateFollowUpInspection';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InspectionWaitingModal from '../InspectionWaitingModal/InspectionWaitingModal';
const steps = ['Skoðunaratriði', 'Frágangur'];
const InspectionFollowUpInit = {
  reasonId: 3,
  identity: 1,
  establishmentName: "",
  billingSentToSSN: "",
  presentName: "",
  billingHour: 0,
  billingHourExtra: 0,
  billingHourTransport: 0,
  billingHourPreperation: 0,
  isInspectionComplete: false,
  isScheduled: false,
  inspectionPerformed: "",
  comment: "",
  authorSSN: "",
  inspectionDetails: [],
  followUpChecksToShow: []
};

const InspectionFollowUpContainer = () => {
  const locationProps = useLocation();
  const { farm, inspection } = locationProps.state;
  const [isFinishing, setIsFinishing] = useState(false);
  const user = useSelector((state) => state.user);
  const [inspectionFollowUpConfirmationModalIsOpen, setInspectionFollowUpConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(inspection ? inspection.id : null);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { createFollowUpInspection } = useCreateFollowUpInspection();
  const { updateFollowUpInspection } = useUpdateFollowUpInspection();
  const { closeInspection } = useCloseInspection();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const windowSize = useWindowSize();
  const isPhone = windowSize.width <= 690;  
  const callBackRef = useRef();
  const isOpenInspection = inspection ? true : false;
  InspectionFollowUpInit.presentName = isOpenInspection ? inspection.companyName : farm.companyName;
  let identity;
  if(isOpenInspection) {
    const tempIdentity = inspection.identity.split("-");
    if(tempIdentity[0] === "bú") {
      identity = "bú-" + tempIdentity[1]
    }
    else {
      identity = inspection.identity
    }
  }else {
    console.log("Identity 2", farm.identity)
    const tempIdentity = farm.identity.split("-");
    if(tempIdentity[0] === "bú") {
      identity = "bú-" + tempIdentity[1]
    }
    else {
      identity = farm.identity
    }  
  }
  const { followUpChecks  } = useGetFollowUpChecksById(identity, isOpenInspection, id);
  const inspectionFollowUpSubmitHandler = async (values) => {
    setIsFinishing(true);
    if(values.id) {
      values.inspectionDetails = [];
    }else {
      values.identity = farm.identity;
      values.billingSentToSSN = farm.companySSN;
      values.establishmentName = farm.name;
      values.authorSSN = user.ssn;
    }
    values.followUpChecksToShow.forEach(fcs => {
      fcs.inspectionDeviationImages.forEach(image => {
        if(image.id) {
          const index = fcs.inspectionDeviationImages.indexOf(image.id);
          fcs.inspectionDeviationImages.splice(index, 1);
        }
      });
      let object;
      if(!fcs.checked) {
          object = {
          inspectionTypeId: fcs.inspectionTypeId,
          inspectionIncludedItems: [fcs.itemId],
          inspectionDeviations: [fcs]
        }
      }else {
        object = {
          inspectionTypeId: fcs.inspectionTypeId,
          inspectionIncludedItems: [fcs.itemId],
        }
      }
      values.inspectionDetails.push(object);       
    });
    if(values.id) {
      await updateFollowUpInspection(values, id);
    }else {
      const inspectionFollowUpDTO = await createFollowUpInspection(values);
      setId(inspectionFollowUpDTO.id);
    }
    setInspectionFollowUpConfirmationModalIsOpen(true);
    setIsFinishing(false);
  }
  const {values, handleChange, handleSubmit, errors, resetFields} = 
  useForm(isOpenInspection ? inspection : InspectionFollowUpInit, inspectionValidation, inspectionFollowUpSubmitHandler); //validate and errors
  followUpChecks && followUpChecks.map(followup => (
    followup.inspectionDeviations.forEach(deviation => {
      if(!values.followUpChecksToShow.some(f => f.itemId === deviation.itemId)) {
        values.followUpChecksToShow.push(deviation)
      }
    })
  ));  
  const handleStep = (step) => () => {
    let hasEmptyJudgment = values.followUpChecksToShow.some(fcts => fcts.judgementId === "");
    if(hasEmptyJudgment) {
      toast.warning(`Einhver atriði hafa ekki verið dæmd`);
    }else {
      setActiveStep(step);
    }
  };
  const handleAddItemsToFollowUp = (items) => {
    items.forEach(item => {
      values.followUpChecksToShow.push(item)
    });
  }
  const handleRemoveItemFromFollowUp = (item) => {
    let isInitialData = false;
    followUpChecks.forEach(fc => {
      let find = fc.inspectionDeviations.find(insp => insp.id === item.id);
        if(find) {
          isInitialData = true;
        }
    });
    if(isInitialData) {
      toast.error(`Ekki er hægt að eyða grunngagni`);
    }else {
      var indexToRemove = values.followUpChecksToShow.map((fc) => fc.itemId).indexOf(item.itemId);
      values.followUpChecksToShow.splice(indexToRemove, 1);
    }
    setRefresh(!refresh);
  }
  const handleConfirmQuit = () => {
    callBackRef.current();
    values.inspectionDetails = [];
  }
  const handleCancelDeletion = () => {
    setConfirmModalIsOpen(false);
  }
  const handleConfirmationCancel = () => {
    values.id = id;
    setInspectionFollowUpConfirmationModalIsOpen(false);
  }
  const handleConfirmation = (id) => {
    callBackRef.current();
    values.inspectionDetails = [];
    closeInspection(id);
    setInspectionFollowUpConfirmationModalIsOpen(false);
    resetFields();
    const path = `/company`;
    navigate(path);
  }
  const handleCancelInspection = () => {
    resetFields();
    values.inspectionDetails.length = 0;
    const path = `/company`
    navigate(path);
  }
  return (
    <div className='inspection-followup-container'>
      <div className={isPhone ? 'inspection-followup-container-stepper-phone' : 'inspection-followup-container-stepper'}>
        <Box sx={{ width: '85%' }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
      <div className='inspection-followup-container-stepper-body'>
        {activeStep === 0 && (
          <InspectionFollowUpView 
            followUpChecksToShow={values.followUpChecksToShow} 
            handleAddItemsToFollowUp={handleAddItemsToFollowUp} 
            callBackRef={callBackRef}
            handleRemoveItemFromFollowUp={handleRemoveItemFromFollowUp}
          />
        )}
       {activeStep === 1 && (
          <InspectionFollowUpAdditionalInfo inspectionValues={values} handleChange={handleChange} errors={errors} handleSubmit={handleSubmit} handleCancelInspection={handleCancelInspection} />
       )
       } 
      </div>
      {isFinishing && <InspectionWaitingModal text="Þú verður að vera rólegur þú æsir upp öll hin ..." />}
      <InspectionConfirmationModal 
        open={inspectionFollowUpConfirmationModalIsOpen} 
        handleCancel={handleConfirmationCancel} 
        handleConfirm={handleConfirmation} 
        title="Klára skráningu" 
        id={isOpenInspection ? inspection.id : id}
      />
      <ToastContainer autoClose={2000} />
      {/* <ConfirmModal open={confirmModalIsOpen} handleConfirm={handleConfirmQuit} handleCancel={handleCancelDeletion} title="Ertu viss um að þú viljir hætta skráningu?"/> */}
      </div>
  );
}

export default InspectionFollowUpContainer;