import React from "react";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Modal, Paper } from "@material-ui/core";
import CancelButton from "../../Inspection/CancelButton/CancelButton";
import "./CompanyFilter.css";
import useWindowSize from "../../../hooks/useWindowSize";

const CompanyFilter = ({ open, handleClose, inspectionTypes, districts, handleInspectionTypeFilterCheck, handleDistrictFilterCheck, handleDeSelectAll }) => {
  const windowSize = useWindowSize();
  const isPhone = windowSize.width <= 690;
  return (
    <Modal
      className="company-filter-modal"
      open={open}
      onClose={handleClose}
    >
      <Paper className="company-filter-modal-paper">
        <div className={isPhone ? "company-filter-modal-container-phone" : "company-filter-modal-container" }>
          <FormGroup>
              <div className="company-filter-modal-container-upper">
              <FormControlLabel 
                      control={
                      <Checkbox 
                        onClick={handleDeSelectAll}
                        checked={false}
                      />
                      }
              />
              <FormLabel><b>Eftirlitstegund</b></FormLabel>
            </div>
            {
              inspectionTypes.map((i) => (
                  <FormControlLabel 
                    control={
                    <Checkbox 
                      onClick={() => handleInspectionTypeFilterCheck(i.id)}
                      checked={i.filterCheck}
                    />
                    }
                    label={i.isName} 
                    key={i.id}
                    />
              ))
            }
          </FormGroup>
          <FormGroup>
            <FormLabel><b>Umdæmi</b></FormLabel>
            {
              districts.map((i) => (
                  <FormControlLabel 
                    control={
                    <Checkbox 
                      checked={i.filterCheck}
                      onClick={() => handleDistrictFilterCheck(i.id)}
                    />
                    }
                    label={i.isName} 
                    key={i.id}
                    />
              ))
            }
          </FormGroup>
        </div>
        <div className="company-filter-modal-button-area">
          <CancelButton
            title="Loka"
            handleCancel={handleClose}
          />
        </div>
      </Paper>
    </Modal>
  );
};
export default CompanyFilter;