import { useState, useEffect } from "react";
import companyService from "../services/companyService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";
import followupService from "../services/followupService";

const useGetFollowUpChecksById = (id, isOpenInspection, inspectionId) => {
  const [followUpChecks, setFollowUpChecks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [isTokenExpired, setIsTokenExpired] = useState(new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date());
  const { refreshToken } = useRefreshToken();
  if(isTokenExpired) {
    refreshToken(user);
    setIsTokenExpired(user.tokenValidTo < new Date())
  }

  useEffect(() => {
    !isTokenExpired &&  
      !isOpenInspection ? 
      companyService
      .getFollowUpChecksById(id, user.accessToken.rawData)
      .then((checks) => {
        console.log("imhere", checks)
        checks.forEach(check => {
          check.checked = false;
        });
        checks.followUpChecksToShow = checks.inspectionDetails;
        setFollowUpChecks(checks);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      })
      :
      followupService
      .getFollowUpInspectionById(inspectionId, user.accessToken.rawData)
      .then((checks) => {
        console.log("imhere", checks)
        checks.forEach(check => {
          check.checked = false;
        });
        checks.followUpChecksToShow = checks.inspectionDetails;
        setFollowUpChecks(checks);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, user.accessToken.rawData, isTokenExpired, isOpenInspection]);
  return { followUpChecks, isLoading, error };
};
export default useGetFollowUpChecksById;