import React, { useRef, useState } from 'react';
import {Box, Stepper, Step, StepButton} from '@mui/material';
import "./InspectionContainer.css";
import InspectionView from '../InspectionView/InspectionView';
import useForm from "../../../hooks/useForm";
import InspectionAdditionalInfoView from '../InspectionAdditionalInfoView/InspectionAdditionalInfoView';
import inspectionValidation from "../../../validations/inspectionValidation";
import useCreateInspection from '../../../hooks/useCreateInspection';
import { useLocation, useNavigate } from 'react-router';
import useWindowSize from '../../../hooks/useWindowSize';
import useUpdateInspection from '../../../hooks/useUpdateInspection';
import { useSelector } from 'react-redux';
import InspectionConfirmationModal from '../InspectionConfirmationModal/InspectionConfirmationModal';
import useCloseInspection from '../../../hooks/useCloseInpsection';
import useGetIsleyfurChecksById from '../../../hooks/useGetIsleyfurChecksById';
import InspectionWaitingModal from '../InspectionWaitingModal/InspectionWaitingModal';

const steps = ['Skoðunaratriði', 'Frágangur'];

const InspectionInit = {
  reasonId: "",
  identity: 1,
  establishmentName: "",
  billingSentToSSN: "",
  presentName: "",
  billingHour: 0,
  billingHourExtra: 0,
  billingHourOvertime: "",
  billingHourTransport: 0,
  billingHourPreperation: 0,
  isInspectionComplete: false,
  isScheduled: false,
  inspectionPerformed: "",
  comment: "",
  authorSSN: "",
  inspectionDetails: []
};

const InspectionContainer = () => {
  // Exlude items skoða 2 stök - valdi 1
  const [activeStep, setActiveStep] = useState(0);
  const [isFinishing, setIsFinishing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inspectionItemModalIsOpen, setInspectionItemModalIsOpen] = useState(
    false
  );
  const [id, setId] = useState(null);
  const [inspectionDetailSubmitModalIsOpen, setInspectionDetailSubmitModalIsOpen] = useState(false);
  const [inspectionConfirmationModalIsOpen, setInspectionConfirmationModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const user = useSelector((state) => state.user);
  const isPhone = windowSize.width <= 690;  
  const locationProps = useLocation();
  const { farm, inspection, inspectionTypes, inspectionReasonId, inspectionTypeInInspection } = locationProps.state;
  const isOpenInspection = inspection ? true : false;
  InspectionInit.presentName = isOpenInspection ? inspection.companyName : farm.companyName;
  const inspectionTypesSelected = inspectionTypes.filter(inspectionType => inspectionTypeInInspection.includes(inspectionType.id));
  let identity;
  if(isOpenInspection) {
    const tempIdentity = inspection.identity.split("-");
    if(tempIdentity[0] === "bú") {
      identity = "Bu-" + tempIdentity[1]
    }
  }else {
    const tempIdentity = farm.identity.split("-");
    if(tempIdentity[0] === "bú") {
      identity = "Bu-" + tempIdentity[1]
    }  
  }
  const { isleyfurChecks } = useGetIsleyfurChecksById(identity);
  const { createInspection } = useCreateInspection();
  const { updateInspection } = useUpdateInspection();
  const { closeInspection } = useCloseInspection();
  const getSubmitInformationForCreate = () => {
    values.establishmentName = farm.name;
    values.billingSentToSSN = farm.companySSN;
    values.identity = farm.identity;
    values.authorSSN = user.ssn;
    values.reasonId= inspectionReasonId;
    if(values.billingHourOvertime.length < 1) {
      values.billingHourOvertime = 0;
    }
  }
  const inspectionSubmitHandler = async (values) => {
    if(inspection) {
      values.reasonId = inspection.reasonId;
    }else {
      getSubmitInformationForCreate();
    }
    if(isSaving) {
      if(values.id) {
        values.authorSSN = user.ssn;
        await updateInspection(values)
      }else {
        const inspection = await createInspection(values);
        values.id = inspection.id;
      }
      setIsSaving(false);
    }
    if(isFinishing) {
      if(values.id) {
        await updateInspection(values)
        setId(values.id)
        setInspectionConfirmationModalIsOpen(true);
      }else {
        const inspectionDTO = await createInspection(values);
        inspectionDTO.inspectionDetails.map((detail) => {
          var detailToChange = values.inspectionDetails.find(detailDTO => detailDTO.inspectionTypeId === detail.inspectionTypeId);
          detailToChange.id = detail.id;
          detailToChange.inspectionId = detail.inspectionId;
          return detailToChange;
        })
        setId(inspectionDTO.id)
        setInspectionConfirmationModalIsOpen(true);
      }
      setIsFinishing(false);
    }
  }
  const {values, handleChange, handleSubmit, errors, resetFields} = 
    useForm(inspection ? inspection : InspectionInit, inspectionValidation, inspectionSubmitHandler); //validate and errors
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleFinishInspection = (e) => {
    setIsFinishing(true);
    handleSubmit(e);
  }
  const handleSaveInspection = (e) => {
    setIsSaving(true);
    handleSubmit(e);
  }
  const handleConfirmationCancel = (id) => {
    values.id = id;
    setInspectionConfirmationModalIsOpen(false);
  }
  const callBackRef = useRef();
  const handleConfirmation = (id) => {
    callBackRef.current();
    closeInspection(id);
    setInspectionConfirmationModalIsOpen(false);
    resetFields();
    values.inspectionDetails.length = 0;
    InspectionInit.inspectionDetails.length = 0;
    const path = `/company`;
    navigate(path);
  }
  const handleCancelInspection = () => {
    resetFields();
    values.inspectionDetails.length = 0;
    const path = `/company`
    navigate(path);
  }
  return (
    <div className='inspection-container'>
      <div className={isPhone ? 'inspection-container-stepper-phone' : 'inspection-container-stepper'}>
        <Box sx={{ width: '85%' }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
      <div className='inspection-container-stepper-body'>
        {activeStep === 0 && (
          <InspectionView 
            inspectionValues={values} 
            inspectionTypes={inspectionTypesSelected} 
            isOpenInspection={isOpenInspection} 
            inspectionItemModalIsOpen={inspectionItemModalIsOpen} 
            setInspectionItemModalIsOpen={setInspectionItemModalIsOpen}
            inspectionDetailSubmitModalIsOpen={inspectionDetailSubmitModalIsOpen}
            setInspectionDetailSubmitModalIsOpen={setInspectionDetailSubmitModalIsOpen}
            callBackRef={callBackRef}
            isleyfurChecks={isleyfurChecks}
          />
        )}
       {activeStep === 1 && (
        <InspectionAdditionalInfoView inspectionValues={values} handleChange={handleChange} errors={errors} handleSubmit={inspectionSubmitHandler} handleSave={handleSaveInspection} handleFinish={handleFinishInspection} handleCancelInspection={handleCancelInspection}/>
       )
       } 
      </div>
      {isFinishing && <InspectionWaitingModal text="Þú verður að vera rólegur þú æsir upp öll hin ..." />}
      {id &&
        <InspectionConfirmationModal 
          open={inspectionConfirmationModalIsOpen} 
          handleCancel={handleConfirmationCancel} 
          handleConfirm={handleConfirmation} 
          title="Klára skráningu" 
          id={isOpenInspection ? inspection.id : id}
        /> 
      }
    </div>
  );
}

export default InspectionContainer;
