import React, { useState } from "react";
import "./CompanyView.css";
import SearchBar from "../../SearchBar/SearchBar";
import useSearchBar from "../../../hooks/useSearchBar";
import CompanyItemList from "../CompanyItemList/CompanyItemList";
import { useNavigate } from "react-router";
import FilterListIcon from '@material-ui/icons/FilterList';
import CompanyFilter from "../CompanyFilter/CompanyFilter";
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import { FormControlLabel, Switch } from "@mui/material";

const CompanyView = ({ filteredInspectionSchedule, inspectionTypes, districts, handleInspectionTypeFilterCheck, handleDistrictFilterCheck, handleDeSelectAllInspectionTypeFilterCheck }) => {
  const [companyFilterIsOpen, setCompanyFilterIsOpen] = useState(false);
  const [isSearchingZipCode, setIsSearchingZipCode] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState(2);
  const { searchResult, handleSearchBarChange, searchTerm } = useSearchBar(
    filteredInspectionSchedule,
    isSearchingZipCode ? "zipCode" : "searchKey"
  );
  let navigate = useNavigate(); 
  const handleSelectFarm = (farm) => {
    //let path = `/company/${farm.identity}`; 
    let path = `/preparation`; 
    navigate(path, {state:{farm: farm}});
  }
  const handleOpenCompanyFilter = () => {
    setCompanyFilterIsOpen(true);
  }
  const handleCloseCompanyFilter = () => {
    setCompanyFilterIsOpen(false);
  }
  const handleChangeReasonId = () => {
    if(selectedReasonId === 2) {
      setSelectedReasonId(3)
    }else {
      setSelectedReasonId(2)
    }
  }
  const handleSearchZipCode = () => {
    setIsSearchingZipCode(!isSearchingZipCode);
  }
  const itemsToShow = searchResult.filter(f => f.reasonId === selectedReasonId).splice(0,100);
  console.log(itemsToShow)
  console.log("imhere")
  console.log(isSearchingZipCode)
  return (
    <div className="company-view">
      <div className="company-loaded" aria-label="simple table">
        <div className="company-loaded-upper">
          <div className="company-loaded-upper-buttons">
            <FormControlLabel
              className="company-reason-id-icon"
              control={
                <Switch checked={selectedReasonId === 2} onChange={handleChangeReasonId} />
              }
              label={selectedReasonId === 2 ? "Reglubundið" : "Eftirfylgni"}
              color="warning"
            />
            <FilterListIcon fontSize="large" className="company-loaded-filter-icon" onClick={handleOpenCompanyFilter}/>
          </div>
        <div className="company-loaded-upper-search">
          {isSearchingZipCode ? 
            <SearchBar
              searchTerm={searchTerm}
              handleSearchBarChange={handleSearchBarChange}
              placeHolder="Leita eftir póstnúmeri"
            />
            :
            <SearchBar
                searchTerm={searchTerm}
                handleSearchBarChange={handleSearchBarChange}
                placeHolder="Leita að eftirlitsþega"
              />
          }
          <FindReplaceIcon 
            className="company-zip-code-icon" 
            onClick={handleSearchZipCode} 
          />
        </div>
        </div>
        <CompanyItemList
          companies={itemsToShow}
          handleSelectFarm={handleSelectFarm}
        />
      </div>
      <CompanyFilter
       open={companyFilterIsOpen} 
       handleClose={handleCloseCompanyFilter} 
       inspectionTypes={inspectionTypes}
       districts={districts}
       handleInspectionTypeFilterCheck={handleInspectionTypeFilterCheck} 
       handleDistrictFilterCheck={handleDistrictFilterCheck}
       handleDeSelectAll={handleDeSelectAllInspectionTypeFilterCheck}
       />
    </div>
  );
};

export default CompanyView;